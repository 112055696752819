<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >
    <template #button-content>
      <div class="d-sm-flex d-none user-nav">
        <p class="user-name font-weight-bolder mb-0">
          {{ userData.firstName || userData.lastName }}
        </p>
        <!-- <span class="user-status">{{ userData.role }}</span> -->
      </div>
      <b-avatar
        size="40"
        :src="userData.avatar"
        variant="light-primary"
        badge
        class="badge-minimal"
        badge-variant="success"
      >
        <feather-icon
          v-if="!userData.userName"
          icon="UserIcon"
          size="22"
        />
      </b-avatar>
    </template>

    <b-dropdown-item
        link-class="d-flex align-items-center"
        :to="{ name: 'pages-account-setting'}"
      >
        <feather-icon
          size="16"
          icon="SettingsIcon"
          class="mr-50"
        />
      <span>{{$t('settings')}}</span>
    </b-dropdown-item>

    <b-dropdown-item
      link-class="d-flex align-items-center"
      @click="logout"
    >
      <feather-icon
        size="16"
        icon="LogOutIcon"
        class="mr-50"
      />
      <span>{{$t('buttons.logout')}}</span>
    </b-dropdown-item>
  
  </b-nav-item-dropdown>
</template>

<script>
import { validateTokenRefreshData } from '@/auth/utils'
import axios from 'axios'
import {
  BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import {disconnectSocket} from "@/libs/socket-io/socket";
import store from "@/store";

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      avatarText,
    }
  },
  methods: {
    logout() {
      // Remove auth-token localStorage
      store.commit('user/SET_ACCESS_TOKEN', null)
      localStorage.removeItem('access_token')
      store.commit('user/SET_REFRESH_TOKEN', null)
      localStorage.removeItem('refresh_token')
      localStorage.removeItem('auth-token')
      // Remove userData 
      localStorage.removeItem('userData')
      localStorage.removeItem('whitelabels')
       // Remove permissions 
      localStorage.removeItem('permissions')
      // this.getImages()
      // validateTokenRefreshData()
      // Redirect to login page
      this.$router.push({ name: 'auth-login' }).then(()=>{
          this.$store.commit('whitelabelCurrencyNabvar/SELECT_WHITELABEL', null)
      })

      //socket-io disconnect from server
      disconnectSocket()

    },
    // async getImages(){
    //   try {
    //     const { data } = await axios.get(process.env.VUE_APP_URL+'/tokenCheck')
    //     if(data.data?.whitelabel){
    //       this.store.commit('user/SET_LOGO', data.data?.whitelabel?.logo || '')
    //       this.store.commit('user/SET_WALLPAPE_URL', data.data?.whitelabel?.wallpaperUrl || '')
    //     }
    //   } catch (error) {
    //     console.log(JSON.stringify(error))
    //     console.log(error, "aqui el error")
    //     if(error.response.data?.whitelabel){
    //       this.store.commit('user/SET_LOGO', error.response.data?.whitelabel?.logo || '')
    //       this.store.commit('user/SET_WALLPAPE_URL', error.response.data?.whitelabel?.wallpaperUrl || '')
    //     }
    //   }
    // }
  },
}
</script>
